import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoBellFill } from "react-icons/go";
import Loader from "../../ui/Loader";
import { GoDotFill } from "react-icons/go";
import { FaCheck } from "react-icons/fa";

export default function NotificationBell() {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const getNotifications = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(`${base_URL}/notifications/within-30-minutes`, {
        method: "GET",
      });
      const data = await res.json();
      setNotifications(data.data.notifications); // Set only the notifications array
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const readNotification = (id) => {
    const url = `${base_URL}/notifications/${id}`;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to read notification");
        }
        console.log("Notification marked as read");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        getNotifications();
      });
  };

  const readAllNotifications = () => {
    const url = `${base_URL}/notifications/mark-all-read`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to mark all notifications as read");
        }
        console.log("All notifications marked as read");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        getNotifications();
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="relative pt-3">
      <button
        className="relative text-[#37B5FE] transition hover:cursor-pointer"
        onClick={() => setIsNotificationOpen((prev) => !prev)}
      >
        <GoBellFill className="h-8 w-8" />
        {notifications.filter((notification) => notification.read === false)
          .length > 0 ? (
          <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold px-0 py-0 rounded-full">
            {notifications.filter((notification) => notification.read === false)
              .length > 99
              ? "99+"
              : notifications.filter(
                  (notification) => notification.read === false
                ).length}
          </span>
        ) : (
          ""
        )}
      </button>

      {isNotificationOpen && (
        <div
          className="absolute top-8 right-0 w-80 bg-white dark:bg-gray-700 rounded-lg shadow-lg z-50 p-4"
          onMouseEnter={() => setIsNotificationOpen(true)}
          onMouseLeave={() => setIsNotificationOpen(false)}
        >
          {isLoading ? (
            <Loader />
          ) : notifications.length > 0 ? (
            <div>
              <ul className="space-y-2">
                {notifications?.map((notification) => (
                  <li
                    key={notification._id}
                    className="flex items-start space-x-2 p-2 dark:hover:bg-gray-600 rounded"
                  >
                    {notification.read == false && (
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <p className="text-sm flex items-center -ml-4 text-blue-800 font-bold dark:text-gray-200">
                            <span className="mr-2 text-blue-500">
                              <GoDotFill className="w-1.5 h-1.5" />
                            </span>
                            <span className="mr-2 text-black dark:text-gray-300">
                              {t("notification.notificationText")}
                            </span>
                            {notification.read === false &&
                              notification.lead_name}
                          </p>
                        </div>
                        <div
                          onClick={() => readNotification(notification._id)}
                          className="text-blue-500 ml-20 cursor-pointer"
                        >
                          <FaCheck />
                        </div>
                      </div>
                    )}
                    {notification.read == true && (
                      <p className="text-sm ml-2 text-gray-800 font-medium dark:text-gray-200">
                        <span className="mr-2 font-normal text-black dark:text-gray-300">
                          {t("notification.notificationText")}
                        </span>
                        {notification.read == true && notification.lead_name}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
              <button
                onClick={() => readAllNotifications()}
                className="w-full text-center dark:text-blue-50 text-blue-500 mt-3 hover:underline"
              >
                {t("notification.notificationViewAll")}
              </button>
            </div>
          ) : (
            <div>
              <p className="text-center my-5 font-semibold dark:text-white">
                {t("notification.notificationNoDate")}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
