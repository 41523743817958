const translationsRU = {
  sideBar: {
    Dashboard: "Панель управления",
    Leads: "Лиды",
    Customers: "Клиенты",
    Contracts: "Контракты",
    StockProducts: "Склад продуктов",
    Managers: "Менеджеры",
    Purchases: "Закупки",
    Conversions: "Конверсии",
  },
  search: {
    searchPlaceholder: "Поиск...",
  },
  filter: {
    filterTitle: "Фильтр по статусу",
    filterName: "Все статусы",
    status: {
      contracted: "Заключен договор",
      interested: "Заинтересован",
      potential: "Потенциальный",
      purchased: "Куплено",
      offered: "Предложено",
    },
  },
  status: {
    contracted: "Заключен договор",
    interested: "Проявил интерес",
    potential: "Потенциальный",
    purchased: "Куплено",
    offered: "Предложено",
  },
  dashboard: {
    dashboardTitle: "Панель управления",
    dashboardTableTitle: "Обязанности по звонкам",
    dashboardNoData: "Данные отсутствуют",
    table: {
      name: "Имя",
      phoneNumber: "Номер телефона",
      status: "Статус",
      whenToCall: "Когда звонить",
      noScheduleSet: "Расписание не установлено",
    },
  },
  leads: {
    leadsTitle: "Лиды",
    leadsTableName: "Список лидов",
    leadFilter: {
      filter: "Фильтр",
      whenToCall: "Когда позвонить",
      nearestToCurrentDate: "Ближе к текущей дате",
      farthestFromCurrentDate: "Дальше от текущей даты",
      status: "Статус",
    },
    leadsAdd: "Добавить Лид",
    leadsTable: {
      id: "ID",
      name: "Имя",
      phone: "Телефон",
      whenToCall: "Время звонка",
      status: "Статус",
      actions: "Действия",
    },
    leadmodal: {
      leadsModalTitleAdd: "Добавить новый лид",
      leadsModalTitleEdit: "Редактировать лид",
      name: "Имя",
      phone: "Номер телефона",
      whenToCall: "Время для звонка",
      status: "Статус",
      interested: "Заинтересован",
      contracted: "Заключен контракт",
      potential: "Потенциальный",
      purchased: "Куплено",
      offered: "Предложено",
      deleteButton: "Удалить",
      saveButton: "Сохранить",
      closeButton: "Закрыть",
      commentTitle: "Комментарии",
      commentPlaceholder: "Добавить комментарий",
      commentSave: "Сохранить",
      commentSend: "Отправить",
    },
  },
  customers: {
    customerTitle: "Клиенты",
    customersTableName: "Список клиентов",
    customerAddButton: "Добавить клиента",
    customerNoData: "Клиенты отсутствуют",
    customerTable: {
      id: "ID",
      name: "Имя",
      phoneNumber: "Телефонный номер",
      totalSpend: "Общие расходы",
      contracts: "Контракты",
      actions: "Действия",
      date: "Дата",
    },
    customerModal: {
      editCustomer: "Редактировать клиента",
      addCustomer: "Добавить клиента",
      name: "Имя",
      phone: "Телефон",
      close: "Закрыть",
      save: "Сохранить",
      delete: "Удалить",
      deleteConfirmation: "Вы уверены, что хотите удалить этого клиента?",
    },
    customerContactModal: {
      customerContractModal: "Контракты ",
      customerContractModalId: "ID",
      customerContractModalName: "Название",
      customerContractModalPrice: "Цена",
      customerContractModalQuantity: "Количество",
      customerContractModalDate: "Дата",
      customerContractModalTotalAmount: "Общая сумма",
      customerContractModalManagerName: "Менеджер",
    },
  },
  contracts: {
    contractTitle: "Контракты",
    contractTableName: "Список контрактов",
    contractAddBTN: "Добавить контракт",
    contractsNoData: "Нет доступных контрактов",
    fiter: {
      contractFilterTitle: "Фильтр",
      contractFilterBy: "Дата",
      contractFilterByName: "Название продукта",
      productNameFilter: "Название продукта",
      contractFilterByA: "По возрастанию",
      contractFilterByD: "По убыванию",
    },
    table: {
      contractTableId: "ID",
      contractTableProduct: "Продукт",
      contractTableCustomer: "Клиент",
      contractTableUnits: "Единицы",
      contractTableDate: "Дата",
      contractTablePrice: "Цена",
      contractTableManager: "Менеджер",
      contractTableTotalsum: "Общая сумма",
      contractTableActions: "Действия",
    },
    contractsModal: {
      contractEdit: "Редактировать контракт",
      contractAdd: "Добавить контракт",
      contractProduct: "Продукт",
      contractProductPlaceholder: "Ищите продукты...",
      contractNoProduct: "Продукты не найдены",
      contractCustomer: "Клиент",
      contractCustomerPlaceholder: "Ищите клиента...",
      contractNoCustomer: "Клиенты не найдены",
      contractPrice: "Цена",
      contractUnits: "Единицы",
      contractManager: "Менеджер",
      contractManagerPlaceholder: "Ищите менеджера...",
      contractNoManager: "Менеджеры не найдены",
      contractDate: "Дата",
      contractDeleteBTN: "Удалить",
      contractSaveBTN: "Сохранить",
      contractAddBTN: "Добавить",
      contractCancelBTN: "Отменить",
    },
  },
  products: {
    productsTitle: "Товар на складе",
    productTableTitle: "Заказы",
    productsNoProduct: "Товары не найдены",
    productTable: {
      productTableId: "ID",
      productTableName: "Название",
      productTablePurchased: "Закуплено",
      productTableSold: "Продано",
      productTableStock: "Остаток на складе",
    },
  },
  managers: {
    managerTitle: "Менеджеры",
    managerTableTitle: "Список менеджеров",
    managerAddBTN: "Добавить менеджера",
    managerNoManager: "Менеджеры отсутствуют",
    managerModal: {
      managerModalEdit: "Редактировать менеджера",
      managerModalAdd: "Добавить менеджера",
      managerModalName: "Имя",
      managerModalPhone: "Телефон",
      managerModalDeleteBTN: "Удалить",
      managerModalCancelBTN: "Отмена",
      managerModalSaveBTN: "Сохранить изменения",
      managerModalAddBTN: "Добавить менеджера",
      managerModalConfirmTitle: "Подтвердить удаление",
      managerModalConfirmText:
        "Вы уверены, что хотите удалить этого менеджера? Это действие нельзя будет отменить.",
      managerModalConfirmCencel: "Отмена",
      managerModalConfirmConfirm: "Подтвердить",
    },
    managersTable: {
      managerTableId: "ID",
      managerTableName: "Имя",
      managerTablePhone: "Номер телефона",
      managerTableSales: "Продажи",
      managerTableContracts: "Контракты",
      managerTableActions: "Действия",
    },
    managersContractsModal: {
      managerContractsTitle: "Детали клиента",
      managerContractsFilterTitle: "Товар",
      managerContractsFilterSelect: "Выберите товар",
      managerContractTableCustomer: "Клиент",
      managerContractTableDate: "Дата",
      managerContractTablePrice: "Цена",
      managerContractTableProduct: "Товар",
      managerContractTableQuantity: "Количество",
      managerContractTableTotal: "Итого",
      managerContractTableNoData: "Контракты отсутствуют.",
      managerContractTableClose: "Закрыть",
      managerContractTableSave: "Сохранить",
      managerTotalSales: "Общая сумма продаж: ",
    },
  },
  purchases: {
    purchasesTitle: "Покупки",
    purchasesTableTitle: "Список покупок",
    purchasesAddBTN: "Добавить покупку",
    purchasesNoData: "Покупки отсутствуют",
    purchasesFilterTitle: "Все Товары",
    purchasesTable: {
      purchasesTableId: "ID",
      purchasesTableSupplier: "Поставщик",
      purchasesTableItems: "Товары",
      purchasesTableQuantity: "Количество",
      purchasesTableFree: "Бесплатно",
      purchasesTableAdditionalCost: "Дополнительные расходы",
      purchasesTablePrice: "Цена",
      purchasesTableTotal: "Итого",
      purchasesTableDate: "Дата",
      purchasesTableActions: "Действия",
    },
    purchasesModal: {
      purchasesModalSupplier: "Поставщик",
      purchasesModalName: "Название",
      purchasesModalQuantity: "Количество",
      purchasesModalQuantityForFree: "Количество бесплатно",
      purchasesModalPrice: "Цена",
      purchasesModalAdditionalCost: "Дополнительные расходы",
      purchasesModalDate: "Дата",
      purchasesModalDeleteBTN: "Удалить",
      purchasesModalCloseBTN: "Закрыть",
      purchasesModalSaveBTN: "Сохранить",
      purchasesModalConfirmTitle: "Подтвердить удаление",
      purchasesModalConfirmText:
        "Вы уверены, что хотите удалить этот элемент? Это действие нельзя будет отменить.",
      purchasesModalConfirmCancelBTN: "Отмена",
      purchasesModalConfirmConfirmBTN: "Подтвердить",
    },
  },
  conversions: {
    conversionsTitle: "Конверсии",
    conversionsTableTitle: "Конверсии лидов",
  },
  notification: {
    notificationViewAll: "Пометить все как прочитанные",
    notificationNoDate: "У вас нет уведомлений",
    notificationText: "Не забудьте позвонить: ",
  },
};

export default translationsRU;
