const translationsUZ = {
  sideBar: {
    Dashboard: "Boshqaruv paneli",
    Leads: "Mijozlar",
    Customers: "Xaridorlar",
    Contracts: "Shartnomalar",
    StockProducts: "Mahsulotlar ombori",
    Managers: "Menejerlar",
    Purchases: "Xaridlar",
    Conversions: "Konvertatsiyalar",
  },
  search: {
    searchPlaceholder: "Qidiruv...",
  },
  filter: {
    filterTitle: "Holat bo‘yicha filtrlash",
    filterName: "Barcha holatlar",
    status: {
      contracted: "Shartnoma tuzilgan",
      interested: "Qiziqqan",
      potential: "Potensial",
      purchased: "Sotib olingan",
      offered: "Taklif qilingan",
    },
  },
  status: {
    contracted: "Shartnoma tuzilgan",
    interested: "Qiziqish bildirgan",
    potential: "Potensial",
    purchased: "Sotib olingan",
    offered: "Taklif qilingan",
  },
  dashboard: {
    dashboardTitle: "Boshqaruv paneli",
    dashboardTableTitle: "Call Duties",
    dashboardNoData: "Ma'lumotlar mavjud emas",
    table: {
      name: "Ism",
      phoneNumber: "Telefon raqami",
      status: "Holat",
      whenToCall: "Qachon qo‘ng‘iroq qilish",
      noScheduleSet: "Jadval belgilanmagan",
    },
  },
  leads: {
    leadsTitle: "Mijozlar",
    leadsTableName: "Mijozlar ro'yxati",
    leadFilter: {
      filter: "Filtr",
      whenToCall: "Qachon qo'ng'iroq qilish",
      nearestToCurrentDate: "Hozirgi sanaga eng yaqin",
      farthestFromCurrentDate: "Hozirgi sanadan eng uzoq",
      status: "Holat",
    },
    leadsAdd: "Mijoz qo'shish",
    leadsTable: {
      id: "ID",
      name: "Ism",
      phone: "Telefon",
      whenToCall: "Qo'ng'iroq qilish vaqti",
      status: "Holat",
      actions: "Harakatlar",
    },
    leadmodal: {
      leadsModalTitleAdd: "Yangi lead qo'shish",
      leadsModalTitleEdit: "Leadni tahrirlash",
      name: "Ism",
      phone: "Telefon raqami",
      whenToCall: "Qo'ng'iroq qilish vaqti",
      status: "Holat",
      interested: "Qiziqqan",
      contracted: "Shartnoma tuzilgan",
      potential: "Potensial",
      purchased: "Sotib olingan",
      offered: "Taklif qilingan",
      deleteButton: "O'chirish",
      saveButton: "Saqlash",
      closeButton: "Yopish",
      commentTitle: "Izohlar",
      commentPlaceholder: "Izoh qo'shing",
      commentSave: "Saqlash",
      commentSend: "Yuborish",
    },
  },
  customers: {
    customerTitle: "Mijozlar",
    customersTableName: "Mijozlar Ro'yxati",
    customerAddButton: "Mijoz Qo'shish",
    customerNoData: "Mijozlar mavjud emas",
    customerTable: {
      id: "ID",
      name: "Ism",
      phoneNumber: "Telefon raqami",
      totalSpend: "Jami sarflangan",
      contracts: "Shartnomalar",
      actions: "Harakatlar",
      date: "Sana",
    },
    customerModal: {
      editCustomer: "Mijozni tahrirlash",
      addCustomer: "Mijoz qo'shish",
      name: "Ism",
      phone: "Telefon raqam",
      close: "Yopish",
      save: "Saqlash",
      delete: "O'chirish",
      deleteConfirmation: "Siz ushbu mijozni o'chirishni tasdiqlaysizmi?",
    },
    customerContactModal: {
      customerContractModal: "Shartnomalar ",
      customerContractModalId: "ID",
      customerContractModalName: "Nomi",
      customerContractModalPrice: "Narxi",
      customerContractModalQuantity: "Miqdori",
      customerContractModalDate: "Sana",
      customerContractModalTotalAmount: "Umumiy summa",
      customerContractModalManagerName: "Menejer",
    },
  },
  contracts: {
    contractTitle: "Shartnomalar",
    contractTableName: "Shartnomalar ro'yxati",
    contractAddBTN: "Shartnoma qo'shish",
    contractsNoData: "Hech qanday shartnoma mavjud emas",
    fiter: {
      contractFilterTitle: "Filtr",
      contractFilterBy: "Sana",
      contractFilterByName: "Mahsulot nomi",
      productNameFilter: "Mahsulot nomi",
      contractFilterByA: "O'sish tartibi",
      contractFilterByD: "Kamayish tartibi",
    },
    table: {
      contractTableId: "ID",
      contractTableProduct: "Mahsulot",
      contractTableCustomer: "Mijoz",
      contractTableUnits: "Birliklar",
      contractTableDate: "Sana",
      contractTablePrice: "Narx",
      contractTableManager: "Boshqaruvchi",
      contractTableTotalsum: "Jami summa",
      contractTableActions: "Harakatlar",
    },
    contractsModal: {
      contractEdit: "Shartnomani tahrirlash",
      contractAdd: "Shartnoma qo'shish",
      contractProduct: "Mahsulot",
      contractProductPlaceholder: "Mahsulotlarni qidiring...",
      contractNoProduct: "Mahsulotlar topilmadi",
      contractCustomer: "Mijoz",
      contractCustomerPlaceholder: "Mijozni qidiring...",
      contractNoCustomer: "Mijozlar topilmadi",
      contractPrice: "Narx",
      contractUnits: "Birliklar",
      contractManager: "Boshqaruvchi",
      contractManagerPlaceholder: "Boshqaruvchini qidiring...",
      contractNoManager: "Boshqaruvchilar topilmadi",
      contractDate: "Sana",
      contractDeleteBTN: "O'chirish",
      contractSaveBTN: "Saqlash",
      contractAddBTN: "Qo'shish",
      contractCancelBTN: "Bekor qilish",
    },
  },
  products: {
    productsTitle: "Ombordagi mahsulot",
    productTableTitle: "Buyurtmalar",
    productsNoProduct: "Mahsulot topilmadi",
    productTable: {
      productTableId: "ID",
      productTableName: "Nomi",
      productTablePurchased: "Sotib olingan",
      productTableSold: "Sotilgan",
      productTableStock: "Ombordagi miqdor",
    },
    managers: {
      managerTitle: "Menejerlar",
      managerTableTitle: "Menejerlar ro'yxati",
      managerAddBTN: "Menejer qo'shish",
      managerNoManager: "Menejerlar mavjud emas",
      managerModal: {
        managerModalEdit: "Menejerni tahrirlash",
        managerModalAdd: "Menejer qo'shish",
        managerModalName: "Ism",
        managerModalPhone: "Telefon",
        managerModalDeleteBTN: "O'chirish",
        managerModalCancelBTN: "Bekor qilish",
        managerModalSaveBTN: "O'zgarishlarni saqlash",
        managerModalAddBTN: "Menejer qo'shish",
        managerModalConfirmTitle: "O'chirishni tasdiqlash",
        managerModalConfirmText:
          "Siz ushbu menejerni o'chirmoqchiligingizga ishonchingiz komilmi? Ushbu amal qaytarib bo'lmaydi.",
        managerModalConfirmCencel: "Bekor qilish",
        managerModalConfirmConfirm: "Tasdiqlash",
      },
    },
  },
  managers: {
    managerTitle: "Menejerlar",
    managerTableTitle: "Menejerlar ro'yxati",
    managerAddBTN: "Menejer qo'shish",
    managerNoManager: "Menejerlar mavjud emas",
    managerModal: {
      managerModalEdit: "Menejerni tahrirlash",
      managerModalAdd: "Menejer qo'shish",
      managerModalName: "Ism",
      managerModalPhone: "Telefon",
      managerModalDeleteBTN: "O'chirish",
      managerModalCancelBTN: "Bekor qilish",
      managerModalSaveBTN: "O'zgarishlarni saqlash",
      managerModalAddBTN: "Menejer qo'shish",
      managerModalConfirmTitle: "O'chirishni tasdiqlash",
      managerModalConfirmText:
        "Siz ushbu menejerni o'chirmoqchiligingizga ishonchingiz komilmi? Ushbu amal qaytarib bo'lmaydi.",
      managerModalConfirmCencel: "Bekor qilish",
      managerModalConfirmConfirm: "Tasdiqlash",
    },
    managersTable: {
      managerTableId: "ID",
      managerTableName: "Ism",
      managerTablePhone: "Telefon raqami",
      managerTableSales: "Sotuvlar",
      managerTableContracts: "Shartnomalar",
      managerTableActions: "Harakatlar",
    },
    managersContractsModal: {
      managerContractsTitle: "Mijoz tafsilotlari",
      managerContractsFilterTitle: "Mahsulot",
      managerContractsFilterSelect: "Mahsulotni tanlang",
      managerContractTableCustomer: "Mijoz",
      managerContractTableDate: "Sana",
      managerContractTablePrice: "Narx",
      managerContractTableProduct: "Mahsulot",
      managerContractTableQuantity: "Miqdor",
      managerContractTableTotal: "Jami",
      managerContractTableNoData: "Shartnomalar mavjud emas.",
      managerContractTableClose: "Yopish",
      managerContractTableSave: "Saqlash",
      managerTotalSales: "Umumiy savdo summasi: ",
    },
  },
  purchases: {
    purchasesTitle: "Xaridlar",
    purchasesTableTitle: "Xaridlar ro'yxati",
    purchasesAddBTN: "Xarid qo'shish",
    purchasesNoData: "Xaridlar mavjud emas",
    purchasesFilterTitle: "Barcha Mahsulotlar",
    purchasesTable: {
      purchasesTableId: "ID",
      purchasesTableSupplier: "Yetkazib beruvchi",
      purchasesTableItems: "Mahsulotlar",
      purchasesTableQuantity: "Miqdor",
      purchasesTableFree: "Bepul",
      purchasesTableAdditionalCost: "Qo'shimcha xarajatlar",
      purchasesTablePrice: "Narx",
      purchasesTableTotal: "Jami",
      purchasesTableDate: "Sana",
      purchasesTableActions: "Harakatlar",
    },
    purchasesModal: {
      purchasesModalSupplier: "Yetkazib beruvchi",
      purchasesModalName: "Nomi",
      purchasesModalQuantity: "Miqdor",
      purchasesModalQuantityForFree: "Bepul miqdor",
      purchasesModalPrice: "Narx",
      purchasesModalAdditionalCost: "Qo'shimcha xarajatlar",
      purchasesModalDate: "Sana",
      purchasesModalDeleteBTN: "O'chirish",
      purchasesModalCloseBTN: "Yopish",
      purchasesModalSaveBTN: "Saqlash",
      purchasesModalConfirmTitle: "O'chirishni tasdiqlash",
      purchasesModalConfirmText:
        "Ushbu elementni o'chirmoqchiligingizga ishonchingiz komilmi? Ushbu amal qaytarib bo'lmaydi.",
      purchasesModalConfirmCancelBTN: "Bekor qilish",
      purchasesModalConfirmConfirmBTN: "Tasdiqlash",
    },
  },
  conversions: {
    conversionsTitle: "Konvertatsiyalar",
    conversionsTableTitle: "Mijozlar konvertatsiyasi",
  },
  notification: {
    notificationViewAll: "Hammalarni o'qilgan deb belgilash",
    notificationNoDate: "Sizda bildirishnomalar yo'q",
    notificationText: "Qo'ng'iroq qilishni unutmang: ",
  },
};

export default translationsUZ;
